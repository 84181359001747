@tailwind base;
@tailwind components;
@tailwind utilities;

/* SWITCH FONT HERE */
:root {
--global-font-family: 'Roboto', sans-serif; /* Default: Roboto */
/*--global-font-family: 'Libre Caslon Text', serif; /* Alternative: Libre Caslon */

}

/* Apply global font styles to the body */
body {
  font-family: var(--global-font-family);
  font-size: var(--global-font-size);
  font-weight: var(--global-font-weight);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Heading styles to use Libre Caslon */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--global-heading-font-family); /* Libre Caslon for headings */
}

/* Example: Apply specific font settings for buttons, forms, etc. */
button, input, textarea {
  font-family: var(--global-font-family); /* Use Roboto or the global font for form elements */
}

/* Paragraph styles use Libre Caslon */
p {
  font-family: var(--global-font-family); /* Libre Caslon for paragraphs */
}
