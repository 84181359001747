/* Dimmed background */
.dim-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* Main container styling for cookie consent banner */
  .cookie-consent-banner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    color: #333333;
    padding: 30px;
    width: 90%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .banner-content {
    display: flex;
    flex-direction: column;
  }
  
  .banner-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .banner-logo {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .banner-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin: 0;
  }
  
  .cookie-consent-text {
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .cookie-consent-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .accept-button {
    background-color: #007bff;
    color: #ffffff;
    font-size: 15px;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .customize-button {
    background-color: #f5f5f5;
    color: #333333;
    font-size: 15px;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .customize-button:hover {
    background-color: #e0e0e0;
  }
  
  .accept-button:hover {
    background-color: #0056b3;
  }
  