/* Custom styling for the date picker to reduce size and fit well */
.custom-datepicker .react-datepicker {
    width: 100%; /* Full width within the container */
  }
  
  .custom-datepicker .react-datepicker__day--selected {
    background-color: #3b82f6;
    color: white;
    border-radius: 50%;
  }
  
  .custom-datepicker .react-datepicker__day--today {
    background-color: #f0f4f8;
    border-radius: 50%;
  }
  
  .custom-datepicker .react-datepicker__header {
    background-color: #f5f5f5;
    border-bottom: none;
  }
  
  .custom-datepicker .react-datepicker__current-month {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .custom-datepicker .react-datepicker__day-name {
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  .custom-datepicker .react-datepicker__day {
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-datepicker .react-datepicker__day:hover {
    background-color: #e5e7eb;
  }
  
  .custom-datepicker .react-datepicker__navigation {
    top: 10px; /* Adjust the arrow buttons */
  }
  